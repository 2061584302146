<template>
  <BarChart :chart-data="chartData" :options="chartOptions" />
</template>

<script>
import px from 'vue-types'

import { ECONOMY_LEVELS } from '@/constants'

import BarChart from '../../../charts/BarChart.js'
import { pxEco } from '../../Map/types.js'

export default {
  name: 'MatchChartEconomy',
  components: { BarChart },
  props: {
    economy: px.arrayOf(
      px.arrayOf(
        px.shape({
          loadout: px.number,
          eco: pxEco(),
        }).loose
      )
    ),
    type: px.oneOf(['match', 'scrim']).def('match'),
    teams: px.arrayOf(
      px.shape({
        id: px.string,
        name: px.string,
      }).loose
    ),
  },
  computed: {
    chartData() {
      return {
        datasets: [
          // ...this.teams?.map((team, index) => ({
          //   borderColor: 'red',
          //   fill: false,
          //   type: 'line',
          //   data: this.economy?.[index].map(() => 10000),
          // })),

          ...this.teams?.map((team, index) => ({
            label: team.name,
            alpha: 0.5,
            opacity: 0.5,
            backgroundColor: index ? '#ff6347' : '#7fffd4',
            type: 'bar',
            data: this.economy?.[index].map(eco => eco.loadout),
          })),
        ],
        labels: this.economy?.[0].map((_, index) => index + 1),
      }
    },
    chartOptions() {
      const stepSize = 500
      const max = 25000
      const colors = Array.from({ length: 1 + Math.round(max / stepSize) }, (_, idx) => {
        const value = max - idx * stepSize
        const level = this.economyLevels.find(level => (level.min || 0) === value)
        if (level) {
          return level.color
        }
        return 'rgba(150,150,150,0.25)'
      })

      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom',
          align: 'start',
        },
        tooltips: {
          titleFontSize: 16,
          bodyFontSize: 12,
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Round',
              },
              ticks: {
                precision: 0,
                stepSize: 1,
                min: 0,
                max: this.economy?.[0].length,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              type: 'linear',
              position: 'left',
              scaleLabel: {
                display: true,
                labelString: 'Loadout',
              },
              ticks: {
                callback: value => (value % 2000 === 0 ? `${Math.round(value / 1000)}k ` : null),
                precision: 0,
                stepSize: 2000,
                min: 0,
                max,
              },
            },
            {
              display: true,
              type: 'linear',
              position: 'right',
              gridLines: {
                display: true,
                drawTicks: true,
                offsetGridLines: false,
                z: 1,
                zeroLineColor: this.zeroEconomyLevel.color,
                color: colors,
              },
              ticks: {
                callback: value => {
                  const level = this.economyLevels.find(level => (level.min || 0) === value)
                  if (level) {
                    return ` ${level.name}: ${level.rangeLabel} `
                  }
                  return null
                },
                autoSkip: false,
                precision: 0,
                stepSize,
                min: 0,
                max,
              },
            },
          ],
        },
      }
    },
    economyLevels() {
      return Object.values(ECONOMY_LEVELS[this.type] || {})
    },
    zeroEconomyLevel() {
      return this.economyLevels.find(level => (level.min || 0) === 0)
    },
  },
}
</script>

<style scoped></style>
